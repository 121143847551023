import Script from 'next/script';
import { Frame } from '../utils';

type Props = {
    frame: Frame;
};
export const RaptorHeadScript = ({ frame }: Props) => {
    return (
        <Script
            id="raptor-head"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
      window.raptor||(window.raptor={q:[{event:"trackevent",params:{p1:"pageview"}}],push:function(event,params,options){this.q.push({event:event,params:params,options:options})},customerId:"${frame.settings?.raptorCustomerId}"});
      `,
            }}
        />
    );
};
